<template>
  <el-dialog
    class="export-excel-popup"
    title="Export dữ liệu ra excel"
    :width="isContinue ? '1024px' : '500px'"
    :visible="visible"
    :show-close="!exporting"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <el-form v-if="!isContinue" label-position="top">
      <el-form-item label="Tiêu đề file">
        <el-select v-model="activeTab" :disabled="exporting">
          <el-option
            v-for="item in titles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item :label="`Từ dòng:`">
            <el-input-number
              v-model="from"
              :min="1"
              :max="to"
              :disabled="exporting"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="`Đến dòng:`">
            <el-input-number
              v-model="to"
              :min="from"
              :max="total"
              :disabled="exporting"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="exporting" class="mt-4">
        <el-progress
          :percentage="percentage"
          :stroke-width="12"
        ></el-progress>
      </div>
    </el-form>
    <div v-else>
      <h3 class="text-center mt-0">{{ excelTitle }} ({{ exportData.length }})</h3>
      <el-table
        :data="exportData.map((d, index) => ({ ...d, stt: index + 1 }))"
        style="width: 100%"
        height="450"
      >
        <el-table-column
          v-for="column in [
            {
              key: 'stt',
              label: 'STT',
              fixed: 'left'
            },
            ...excelColumns
          ]"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
          :min-width="column.width"
          :fixed="column.fixed"
        >
          <template slot-scope="scope">
            <div class="text-limited text-limited__2" :title="scope.row[column.key]">
              {{ column.locale ? scope.row[column.locale][column.column_key] : scope.row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer">
      <el-button
        v-if="!isContinue"
        type="primary"
        :loading="exporting"
        @click="getExportData"
      >
        Tiếp tục
      </el-button>
      <el-row v-else type="flex" align="middle" justify="end">
        <el-button
          type="info"
          class="mr-2"
          :loading="exporting"
          @click="isContinue = false"
        >
          Quay lại
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          class="mr-2"
          :loading="exporting"
          @click="handleExport"
        >
          Export JSON
        </el-button>
        <vue-excel-xlsx
          :columns="excelColumns"
          :data="excelData"
          :file-name="`${excelTitle}`"
          :sheet-name="`${excelTitle}`"
          style="border: none; padding: 0"
        >
          <el-button
            type="primary"
            icon="el-icon-download"
          >
            Download Excel
          </el-button>
        </vue-excel-xlsx>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listTours } from '@/services/tour'
import { listPlaces } from '@/services/place'
import { listPosts } from '@/services/post'
import { getComments } from '@/services/comment'
import { convertData, JSONToFile } from '@/utils/export'

import TYPE from '@/data/type'

export default {
  name: 'ExportExcelPopup',
  components: {},
  props: {
    visible: Boolean,
    order: String,
    total: Number,
    columns: Array,
    type: String,
    titles: Array,
    detail: Object,
    children: Boolean,
    activeTab: [String, Boolean]
  },
  data () {
    return {
      exporting: false,
      from: 1,
      to: this.total,
      exportData: [],
      isContinue: false,
      importing: false,
      exported: 0,
      exportSize: 100
    }
  },
  computed: {
    ...mapGetters(['language', 'constant']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    excelTitle() {
      return this.titles.find((t) => t.value === this.activeTab).label
    },
    limit() {
      return this.to - this.from + 1
    },
    startPage() {
      return Math.floor((this.from / this.exportSize)) + 1
    },
    endPage() {
      return Math.floor((this.to / this.exportSize)) + 1
    },
    percentage() {
      const result = Math.ceil((this.exported / this.limit * 100))
      return result > 100 ? 100 : result
    },
    requestParams() {
      let params = {
        locale: 'vi',
        order: this.order
      }
      if (this.type === 'comment') {
        params = { ...params, approved: this.activeTab }
      } else {
        if (this.placeType.type === 'tour') {
          params = { ...params, tour_type: this.type }
        } else if (this.placeType.type === 'post') {
          params = { ...params }
        } else {
          params = { ...params, place_types: this.type }
        }
        if (!this.children) {
          params = { ...params, only_parent: true }
        } else {
          params = { ...params, only_children: true }
          if (this.detail && this.detail.type === this.type) {
            params = { ...params, parent_id: this.detail.id }
          }
        }
      }
      if (this.detail && this.detail.type !== this.type) {
        params = { ...params, [`${this.detail.type}_id`]: this.detail.id }
      }
      return params
    },
    locales() {
      return this.constant.constants.locales.filter((l) => l.code !== 'vi')
    },
    excelColumns() {
      const languageColumns = this.columns.filter((c) => c.language)
      const lColumns = this.locales.map((l) => {
        return languageColumns.map((column) => ({
          ...column,
          locale: l.code,
          column_key: column.key,
          key: `${column.key}__${l.code}`,
          label: `${column.label} (${l.code})`
        }))
      }).flat()
      return [...this.columns, ...lColumns].map((c) => ({ ...c, label: c.label.toUpperCase(), field: c.key }))
    },
    excelData() {
      return this.exportData.map(d => {
        const data = {}
        this.excelColumns.forEach((column) => {
          if (column.locale) {
            data[`${column.key}`] = d[column.locale][column.column_key]
          } else {
            data[`${column.key}`] = d[column.key]
          }
        })
        return data
      })
    },
    jsonData() {
      const languageColumns = this.columns.filter((c) => c.language)
      return this.exportData.map(d => {
        const data = {}
        this.columns.forEach((column) => {
          data[`${column.key}`] = d[column.key]
        })
        this.locales.forEach(locale => {
          data[locale.code] = {}
          languageColumns.forEach((lColumn) => {
            data[locale.code][`${lColumn.key}`] = d[locale.code][lColumn.key]
          })
        })
        return data
      })
    }
  },
  methods: {
    async handleListTours(params) {
      return await listTours(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return listTours({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            result[locale.code] = convertData(langData[i].result[index], this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListPosts(params) {
      return await listPosts(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return listPosts({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            result[locale.code] = convertData(langData[i].result[index], this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListPlaces(params) {
      return await listPlaces(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return listPlaces({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            result[locale.code] = convertData(langData[i].result[index], this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async getExportData() {
      this.exportData = []
      let exportData = []
      this.exporting = true
      let page = this.startPage
      let params = this.requestParams
      while (page <= this.endPage) {
        params = {
          ...params,
          page,
          per_page: this.exportSize
        }
        let request = null
        if (this.type === 'comment') {
          request = getComments(params)
        } else if (this.placeType.type === 'tour') {
          request = this.handleListTours(params)
        } else if (this.placeType.type === 'post') {
          request = this.handleListPosts(params)
        } else {
          request = this.handleListPlaces(params)
        }
        await request.then((response) => {
          exportData = [...exportData, ...response.result]
        }).catch(() => {
          exportData = [...exportData]
        })
        this.exported = exportData.length
        page = page + 1
      }
      const offset = this.from - (this.exportSize * (this.startPage - 1)) - 1
      this.exportData = exportData.slice(
        offset > 0 ? offset : 0,
        this.limit + offset
      )
      setTimeout(() => {
        this.isContinue = true
        this.exporting = false
      }, 1000)
    },
    handleExport() {
      JSONToFile(this.jsonData, this.excelTitle)
      this.$emit('close')
      this.$notify.success({
        title: 'Thông báo !',
        message: 'Lưu file JSON thành công !'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.export-excel-popup {
  .el-select, .el-input-number {
    width: 100%;
  }
}
</style>
<style lang="scss">
.export-excel-popup {
  .el-dialog__body {
    padding-top: 10px;
  }
}
</style>
