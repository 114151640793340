<template>
  <div class="table-column">
    <div v-if="column.key === 'stt'">{{ row.stt }}</div>
    <div v-else-if="column.key === 'avatar'">
      <el-row type="flex" align="middle">
        <el-col :lg="8" title="Private">
          <i
            v-if="row.status && row.status === 'private'"
            class="fas fa-lock"
          ></i>
          <i v-else-if="!row.status && !row.public" class="fas fa-lock"></i>
        </el-col>
        <el-col :lg="16" class="avatar-img">
          <img
            :src="
              row.avatar && row.avatar.url ? row.avatar.url : imageDefaultUrl
            "
            alt="href"
          />
          <i v-if="row.vrtour" class="fas fa-play-circle" title="Tour VR"></i>
          <i v-if="row.audio" class="fas fa-volume-up" title="Audio"></i>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="column.key === 'name'">
      <router-link :to="getToShowRouter(row)">{{ row.name }}</router-link>
    </div>
    <div v-if="column.key === 'parent' && row.parent">
      <router-link :to="getToShowRouter(row.parent)">{{ row.parent.name }}</router-link>
    </div>
    <div v-else-if="column.key === 'times'">
      {{ row.start_time }} - {{ row.end_time }}
    </div>
    <div v-else-if="column.key === 'created_at'">{{ row.created_at }}</div>
    <div v-else-if="column.key === 'language'">
      <el-row type="flex" align="middle" justify="center">
        <img
          v-for="locale in locales"
          :key="locale.code"
          class="language"
          :class="{
            inactive:
              !row.translations ||
              !row.translations.find((t) => t.locale === locale.code),
          }"
          :src="require(`@/assets/images/${locale.code}.png`)"
          alt="href"
        />
      </el-row>
    </div>
    <div v-else-if="column.key === 'object'" :title="row.object.name" class="text-limited">
      <router-link :to="getToRouter(row)">{{ row.object.name }}</router-link>
    </div>
    <div v-else-if="column.key === 'creator'">
      {{ row.creator ? row.creator.name : "" }}
    </div>
    <div v-else-if="column.key === 'content'" class="text-limited" :title="row.content">
      {{ row.content }}
    </div>
    <div v-else-if="column.key === 'country'">{{ countryName }}</div>
    <div v-else-if="column.key === 'organization'">{{ row.organization ? row.organization.summary : '' }}</div>
    <div v-else-if="column.key === 'approved'">
      <el-tag v-if="row.approved" type="success" size="small">Đã duyệt</el-tag>
      <el-tag v-else type="info" size="small">Chưa duyệt</el-tag>
    </div>
    <div v-else-if="column.key === 'ordered'">
      <div v-if="!isEdit">
        {{ row.ordered }}
        <i
          class="el-icon-edit ml-1 text-primary cursor-pointer"
          @click="openEditValue(column.key, row[column.key])"
        ></i>
      </div>
      <div v-else class="form-edit">
        <el-input-number
          v-model="newValue"
          size="mini"
          controls-position="right"
          :disabled="callingAPI"
        ></el-input-number>
        <i
          v-if="callingAPI"
          class="el-icon-loading ml-1 text-primary fs-m"
        ></i>
        <i
          v-if="!callingAPI"
          class="el-icon-circle-check ml-1 text-success cursor-pointer fs-m"
          @click="saveNewValue"
        ></i>
        <i
          v-if="!callingAPI"
          class="el-icon-circle-close ml-1 text-danger cursor-pointer fs-m"
          @click="isEdit = false"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCountry, getToShowRouter } from '@/utils/filters'
import { updatePlace } from '@/services/place'
import { updateTour } from '@/services/tour'
import { updatePost } from '@/services/post'

import TYPE from '@/data/type'

export default {
  name: 'TableColumn',
  props: {
    column: Object,
    row: Object,
    type: String,
    language: String
  },
  data() {
    return {
      imageDefaultUrl: '/default-image.jpeg',
      isEdit: false,
      editKey: 'ordered',
      newValue: null,
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['constant']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    locales() {
      return this.constant.constants.locales
    },
    countryName() {
      const c = getCountry(this.row.country_id, this.constant.constants.countries)
      return c ? c.name : ''
    }
  },
  methods: {
    getCountry,
    getToShowRouter,
    getToRouter(row) {
      const firstText = row.object_type.slice(0, 1).toUpperCase()
      const type = `${firstText}${row.object_type.slice(1, row.object_type.length)}`
      return { name: `${type}Info`, params: { id: row.object_id.id }}
    },
    openEditValue(key, oldValue) {
      this.newValue = oldValue
      this.editKey = key
      this.isEdit = true
    },
    saveNewValue() {
      this.row[this.editKey] = this.newValue
      this.updateDetailInfo()
    },
    updateDetailInfo() {
      this.callingAPI = true
      let request = null
      const data = { ...this.row, locale: this.language }
      if (this.placeType.type === 'tour') {
        request = updateTour(data)
      } else if (this.placeType.type === 'post') {
        request = updatePost(data)
      } else {
        request = updatePlace(data)
      }
      request.then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thông tin thành công.'
        })
        this.isEdit = false
      }).catch((error) => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-column {
  .fa-lock {
    color: #ff5252;
  }
  .avatar-img {
    position: relative;
    svg {
      position: absolute;
    }
    .fa-volume-up {
      right: -2px;
      bottom: 5px;
      color: #409eff;
      font-size: 18px;
    }
    .fa-play-circle {
      right: -2px;
      top: 0px;
      font-size: 20px;
      color: #67c23a;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin: 5px 0;
    &.language {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      margin: 0 5px;
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
  a {
    color: #409eff;
  }
  .form-edit {
    display: flex;
    align-items: center;
  }
}
</style>
